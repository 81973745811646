import logo from './assets/img/i-am-coffee.png';
import './App.css';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';

const IAmList = ["an Entrepreneur", "a Software Engineer", "a Baker", "a Barber", "a Coach", "a Busines Owner",];

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Container fluid className="d-flex justify-content-center align-items-center main-content-container">
        <div className="main-content">
          <div className="brand-text animated-text">{"I Am"}</div>
          <div className="main-text-container">
            <ReactTypingEffect
              text={IAmList}
              speed={100}
              eraseSpeed={50}
              eraseDelay={1000}
              cursor=" "
              typingDelay={500}
              displayTextRenderer={(text, i) => {
                return (
                  <h2 className="animated-text">
                    {text}
                  </h2>
                );
              }}
            />
          </div>
        </div>
      </Container>
      <footer className="footer">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} sm={6} md={4} className="text-center text-sm-left">
              <p className="footer-slogan">"Live Your Purposeful Life"</p>
            </Col>
            <Col xs={12} sm={6} md={4} className="text-center text-sm-right">
              <p className="footer-copyright">© 2024 I Am Coffee. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;

function NavigationBar() {
  return (
    <Navbar bg="light" expand="lg" className="shadow">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="I Am Coffee Logo"
          />
          {' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto custom-nav">
            <Nav.Link
              data-placement="bottom"
              href="https://www.youtube.com/@DrinkIAmCoffee"
              target="_blank"
              title="Subscribe to us on Youtube"
            >
              <i className="fa fa-youtube-play nav-icon" />
              <p className="d-lg-none">Youtube</p>
            </Nav.Link>
            <Nav.Link
              data-placement="bottom"
              href="https://www.instagram.com/drinkiamcoffee/"
              target="_blank"
              title="Follow us on Instagram"
            >
              <i className="fa fa-instagram nav-icon" />
              <p className="d-lg-none">Instagram</p>
            </Nav.Link>
            <Nav.Link
              data-placement="bottom"
              href="https://www.linkedin.com/company/i-am-coffee"
              target="_blank"
              title="Follow us on LinkedIn"
            >
              <i className="fa fa-linkedin nav-icon" />
              <p className="d-lg-none">LinkedIn</p>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
